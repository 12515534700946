<template>
    <div>
      <article>
        <section>
          <div class="courseUnits_dialogs">
            <el-dialog
              :title="`Update Company User`"
              :visible.sync="isEditUserModalVisible"
              :destroy-on-close="true"
              :before-close="handleClose"
              style="text-align: left"
            >
              <div v-loading="submitting">
                <el-form
                  :model="userForm"
                  :rules="rules"
                  label-position="top"
                  ref="userForm"
                  class="demo-userForm2"
                >
                  <div class="row">
                    <div class="col-md-6">
                      <el-form-item
                        label="First Name"
                        prop="first_name"
                      >
                        <el-input v-model="userForm.first_name"></el-input>
                      </el-form-item>
                    </div>
                    <div class="col-md-6">
                      <el-form-item
                        label="Last Name"
                        prop="last_name"
                      >
                        <el-input v-model="userForm.last_name"></el-input>
                      </el-form-item>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <el-form-item
                        label="Phone"
                        prop="phone"
                      >
                        <el-input v-model="userForm.phone"></el-input>
                      </el-form-item>
                    </div>
                    <div class="col-md-6">
                      <el-form-item
                        label="Email"
                        prop="email"
                      >
                        <el-input v-model="userForm.email"></el-input>
                      </el-form-item>
                    </div>
                  </div>
  
                  <div class="row">
                    <div class="col-md-6">
                      <el-form-item
                        label="Address"
                        prop="address"
                      >
                        <el-input v-model="userForm.address"></el-input>
                      </el-form-item>
                    </div>
  
                    <div class="col-md-6">
                      
                    </div>
                  </div>
  
                  <div class="row">
                    <div class="col-md-6">
                      <el-form-item
                        label="Choose User roles"
                        prop="roles"
                      >
                        <el-select
                          v-model="userForm.roles"
                          placeholder="Select"
                          class="w-100"
                        >
                          <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                    <div class="col-md-6">
                      <el-form-item
                        label="Choose Gender"
                        prop="gender"
                      >
                        <el-select
                          v-model="userForm.gender"
                          placeholder="Select"
                          class="w-100"
                        >
                          <el-option
                            v-for="gender in genders"
                            :key="gender.id"
                            :label="gender.title"
                            :value="gender.title"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                  </div>
  
                  <div class="row">
                    <div class="col-md-6">
                      <el-form-item label="User Photo">
                        <el-upload
                          class="upload-demo"
                          drag
                          action="''"
                          :on-change="handleUserPhotoPreview"
                          :on-remove="handleUserPhotoRemove"
                          :file-list="userPhotoFileLists"
                          :auto-upload="false"
                          :multiple="false"
                        >
                          <div v-if="!isUploadingFile">
                            <i class="el-icon-upload"></i>
                            <div class="el-upload__text">
                              Drop file here or <em>click to upload</em>
                            </div>
                          </div>
                          <div v-else>
                            <el-progress
                              v-if="imageUploadingProgress != 100"
                              type="circle"
                              :color="progressColors"
                              :percentage="imageUploadingProgress"
                              :width="80"
                              :stroke-width="4"
                            ></el-progress>
                            <el-progress
                              v-else
                              type="circle"
                              :percentage="100"
                              status="success"
                              :width="80"
                              :stroke-width="4"
                            ></el-progress>
                            <div style="font-size: 0.8em; color: rgba(0, 0, 0, 0.8)">
                              {{
                            imageUploadingProgress == 100
                              ? "Uploaded"
                              : "Uploading..."
                          }}
                            </div>
                          </div>
                          <!-- <div class="el-upload__tip" slot="tip">jpg/png files with a size less than 500kb</div> -->
                        </el-upload>
                      </el-form-item>
                    </div>
                  </div>
  
                  <hr />
                  <el-form-item class="text-center">
                    <el-button
                      style="background-color: #083628; color: white;"
                      @click="editUser('userForm')"
                    > Save User Changes</el-button>
                  </el-form-item>
                </el-form>
  
                <div class="text-center">
                  <div class="text-center">
                    <small>© 2022 Access Uganda Safari, All rights reserved.</small>
                  </div>
                  <div class="text-center">
                    <!--v-model="userForm.Course" <small>Design by <a href="https://ovalspace.co" style="color: black;"><strong>Ovalspace</strong></a></small> -->
                  </div>
                </div>
              </div>
            </el-dialog>
          </div>
        </section>
      </article>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        loading: false,
        isLoading: false,
        bookings: [],
        users: [],
        userEditId: "",
        selectedUserPhotoFile: null,
        isUploadingFile: false,
        imageUploadingProgress: 0,
        progressColors: [
          { color: "#f56c6c", percentage: 25 },
          { color: "#e6a23c", percentage: 50 },
          { color: "#1989fa", percentage: 75 },
          { color: "#6f7ad3", percentage: 100 },
        ],
        userPhotoFileLists: [],
        genders: [
          { id: 1, title: "Male" },
          { id: 1, title: "Female" },
        ],
  
        options: [
          {
            value: "AGENT",
            label: "Agent",
          },
          {
            value: "ADMIN",
            label: "Admin",
          },
        ],
  
        isEditUserModalVisible: false,
        years: "",
        year_numbers: [],
        value: "",
        loadingError: false,
        submitting: false,
        course: "",
        userForm: {
          first_name: "",
          last_name: "",
          phone: "",
          email: "",
          address: "",
          roles: "",
          gender: "",
        },
  
        rules: {
          first_name: [
            {
              required: true,
              message: "First Name is required",
              trigger: "blur",
            },
          ],
          last_name: [
            { required: true, message: "Last Name is required", trigger: "blur" },
          ],
          phone: [
            { required: true, message: "Phone is required", trigger: "blur" },
          ],
          email: [
            { required: true, message: "Email is required", trigger: "blur" },
          ],
          address: [
            {
              required: true,
              message: "address is required",
              trigger: "change",
            },
          ],
          gender: [
            {
              required: true,
              message: "Gender is required",
              trigger: "change",
            },
          ],
          roles: [
            {
              required: true,
              message: "User roles is required",
              trigger: "change",
            },
          ]
        },
      };
    },
  
    props: {
      showUserEditDialog: {
        required: true,
        type: Boolean,
      },
      userData: {
        required: true,
        type: Object,
      },
    },
  
    watch: {
      showUserEditDialog() {
        if (this.showUserEditDialog === true) {
          this.isEditUserModalVisible = true;
          this.userForm.first_name = this.userData.first_name;
          this.userForm.last_name = this.userData.last_name;
          this.userForm.address = this.userData.address;
          this.userForm.email = this.userData.email;
          this.userForm.phone = this.userData.phone;
          this.userForm.roles = this.userData.roles;
          this.userForm.gender = this.userData.gender;
          this.selectedUserPhotoFile = this.userData.profile_picture;
        } else {
          this.isEditUserModalVisible = false;
        }
      },
    },
  
    methods: {
      handleClose(done) {
        done();
        this.$emit("closeEditUserDialog");
      },
  
      handleUserPhotoPreview(file) {
        console.log(file.raw);
        this.userPhotoFileLists = [file];
        this.selectedUserPhotoFile = file.raw;
      },
      handleUserPhotoRemove() {
        this.selectedUserPhotoFile = null;
      },
  
      async editUser(formName) {
        this.$refs[formName].validate(async (valid) => {
          if (valid) {
            try {
              this.submitting = true;
              this.isUploadingFile = true;
              const formData = new FormData();
              formData.append("first_name", this.userForm.first_name);
              formData.append("last_name", this.userForm.last_name);
              formData.append("address", this.userForm.address);
              formData.append("email", this.userForm.email);
              formData.append("phone", this.userForm.phone);
              formData.append("roles", this.userForm.roles);
              formData.append("profile_picture", this.selectedUserPhotoFile);
              formData.append("gender", this.userForm.gender);
              let response = await this.$http.patch(
                `company-users/${this.userData.user_id}`,
                formData,
                {
                  onUploadProgress: (progressEvent) => {
                    this.imageUploadingProgress = +(
                      (progressEvent.loaded / progressEvent.total) *
                      100
                    ).toFixed(0);
                  },
                }
              );
              if (
                response.data.success &&
                response.data.message == "USER_UPDATED_SUCCESSFULLY"
              ) {
                this.$refs[formName].resetFields();
                this.$emit("re-fresh");
                this.$notify({
                  title: "Success",
                  message: "User updated successfully",
                  type: "success",
                });
                this.$emit("closeEditUserDialog");
              } else {
                throw "UNEXPECTED_RESPONSE";
              }
            } catch (error) {
              if (error.message == "Network Error") {
                return this.$notify({
                  title: "Connection failed",
                  message: "A network error occurred, please try again.",
                  type: "error",
                });
              }
              this.$notify({
                title: "Unable to update user",
                message: "An unexpected Error occurred, please try again",
                type: "error",
              });
            } finally {
              this.submitting = false;
            }
          } else {
            return false;
          }
        });
      },
    },
  };
  </script>
  
  <style>
  </style>